// extracted by mini-css-extract-plugin
export var container = "shareLarge-module--container--glLp0";
export var mobileDisplay = "shareLarge-module--mobileDisplay--Doixm";
export var containerInner = "shareLarge-module--containerInner--cVivQ";
export var iconContainer = "shareLarge-module--iconContainer--8s5Y8";
export var links = "shareLarge-module--links--0FwWN";
export var copyContainer = "shareLarge-module--copyContainer--moGw0";
export var copyIcon = "shareLarge-module--copyIcon--G5m4K";
export var copyButton = "shareLarge-module--copyButton--p5nSL";
export var notification = "shareLarge-module--notification--KJBMx";
export var button = "shareLarge-module--button--aKMlj";