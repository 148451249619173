import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FaRegCopy } from 'react-icons/fa'

import * as styles from '../styles/shareLarge.module.css'
import { BiCheckDouble } from 'react-icons/bi'

const ShareLarge = ({ url, pageName }) => {
  const [copied, setCopied] = useState(false)
  const copyText = () => {
    if (!copied) {
      navigator.clipboard.writeText(url)
      setCopied(true)
    }
  }
  return (
    <div className={styles.container}>

      {/* Mobile display */}
      <div className={styles.mobileDisplay}>
        <p>Share this {pageName} via:</p>
        <div className={styles.iconContainer}>
          <a
            className={styles.button}
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
            target='_blank'>
            <FontAwesomeIcon
              icon={faLinkedin}
            />
          </a>
          <a
            className={styles.button}
            href={`https://twitter.com/intent/tweet?text=Check out this blog post from Deimos Cloud! - ${url}`}
            target='_blank'>
            <FontAwesomeIcon
              icon={faTwitter}
            />
          </a>
          <button
            className={styles.button}
            disabled={copied}
            onClick={() => copyText()}
          >
            {!copied && <FaRegCopy />}
            {copied && <BiCheckDouble />}
          </button>
        </div>
        <div className={styles.notification}>
          {copied && <span>Link copied!</span>}
        </div>
      </div>

      {/* Desktop display */}
      <div className={styles.containerInner}>
        <p>Share this {pageName} via:</p>
        <div className={styles.iconContainer}>
          <a
            className={styles.links}
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
            target='_blank'>
            <FontAwesomeIcon
              icon={faLinkedin}
            />
            <span>LinkedIn</span>
          </a>
          <a
            className={styles.links}
            href={`https://twitter.com/intent/tweet?text=Check out this blog post from Deimos Cloud! - ${url}`}
            target='_blank'>
            <FontAwesomeIcon
              icon={faTwitter}
            />

            <span>Twitter</span>
          </a>
        </div>
        <span>or copy the link</span>
        <div className={styles.copyContainer}>
          <div className={styles.copyIcon}>
            <FaRegCopy />
          </div>
          <span>{url}</span>
          <button
            className={styles.copyButton}
            disabled={copied}
            onClick={() => copyText()}
          >
            <span>{copied ? `Link Copied!` : `Copy Link`}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

ShareLarge.propTypes = {
  pageName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default ShareLarge
