import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FaRegCopy } from 'react-icons/fa'
import { BiCheckDouble } from 'react-icons/bi'

import PropTypes from 'prop-types'

import * as styles from '../styles/shareSmall.module.css'

const ShareSmall = ({ url }) => {
  const [copied, setCopied] = useState(false)
  const copyText = () => {
    if (!copied) {
      navigator.clipboard.writeText(url)
      setCopied(true)
    }
  }

  return (
    <div className={styles.container} >
      <p>Share this blog post:</p>
      <div className={styles.iconContainer}>
        <a
          className={styles.button}
          href={`https://twitter.com/intent/tweet?text=Check out this blog post from Deimos Cloud! - ${url}`}
          target='_blank'>
          <FontAwesomeIcon
            icon={faTwitter}
          />
        </a>
        <a
          className={styles.button}
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
          target='_blank'>
          <FontAwesomeIcon
            icon={faLinkedin}
          />
        </a>
        <button
          className={styles.button}
          disabled={copied}
          onClick={() => copyText()}
        >
          { !copied && <FaRegCopy /> }
          { copied && <BiCheckDouble /> }
        </button>
      </div>
      <div className={styles.notification}>
        { copied && <span>Link copied!</span> }
      </div>
    </div>
  )
}

ShareSmall.propTypes = {
  url: PropTypes.string.isRequired
}

export default ShareSmall
