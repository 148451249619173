import React from 'react'
import PropTypes from 'prop-types'
import SEO from 'components/seo'
import { graphql, Link } from 'gatsby'
import { composeClasses, formatDateWithDayJs, jsonId } from 'utils'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import * as BlogStyles from 'styles/blog.module.css'
import * as styles from '../styles/blogDetail.module.css'
import * as sharedStyles from '../styles/sharedLayout.module.css'
import parse from 'html-react-parser'
import ShareLarge from 'components/shareLarge'
import ShareSmall from 'components/shareSmall'
import HelmetExport from 'react-helmet'

export const query = graphql`
  query($id: ID!) {
    wordPress {
     teamMembers(first: 100) {
        edges {
          node {
            membersFieldGroup {
              memberFirstName
              memberSurname
              memberJobTitle
            }
          }
        }
      }
      post(id: $id) {
        title
        author {
          node {
            firstName
            lastName
            name
            avatar {
              url
            }
          }
        }
        postFieldGroup {
          minuteRead
        }
        excerpt
        featuredImage {
          node {
            sourceUrl
          }
        }
        date
        modified
        content
        slug
      }
      pages(where: { name: "Post" }) {
        edges {
          node {
            blogFieldGroup {
              gainKnowledge {
                gainMoreKnowledge {
                  ... on wordPress_Post {
                    slug
                    title
                  }
                }
              }
            }
          }
        }
      }
      footers {
        nodes {
          footersFieldGroup {
            socialLinks {
              socialLogo
              socialUrl
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const BlogDetailPage = ({ data, pageContext }) => {
  const post = data.wordPress.post
  const pages = data.wordPress.pages
  const teamMembers = data.wordPress.teamMembers.edges
  const knowledgeGains = pages.edges[0].node.blogFieldGroup.gainKnowledge
  const authorImageUrl = post.author.node.avatar.url
  const postTitle = post.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const url =
    typeof window !== 'undefined'
      ? window.location.href
      : `https://deimos.io/post/${post.slug}`
  const {
    breadcrumb: { crumbs }
  } = pageContext

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'NewsArticle',
          author: {
            '@type': 'Person',
            name: post.author.node.name,
            url: `/member/${post.author.node.firstName.toLowerCase()}-${post.author.node.lastName.toLowerCase()}`
          },
          dateModified: post.modified,
          datePublished: post.date,
          headline: post.title,
          mainEntityOfPage: {
            '@id': 'https://google.com/article',
            '@type': 'WebPage'
          },
          publisher: {
            '@type': 'Organization',
            logo: {
              '@type': 'ImageObject',
              url: 'https://admin.deimos.io/wp-content/uploads/deimosLogo.svg'
            },
            name: 'Deimos Cloud'
          }
        })}
      </HelmetExport>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/post`,
            'name': 'Blog',
            'position': 2
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/${contextSlug}`,
            'name': `${postTitle}`,
            'position': 3
          }]
        })}
      </HelmetExport>
      <SEO
        article
        description={post.excerpt}
        image={post.featuredImage && post.featuredImage.node.sourceUrl}
        title={post.title}
      />
      <div
        className={composeClasses(sharedStyles.wrapper, styles.blogBackground)}
      >
        <div
          className={composeClasses(
            sharedStyles.verticalContainer,
            styles.breadCrumbsSection
          )}
        >
          <div
            className={composeClasses(
              sharedStyles.verticalContainerInner,
              BlogStyles.verticalBlogInner
            )}
          >
            <Breadcrumb
              crumbLabel={post.title}
              crumbs={crumbs}
              crumbSeparator=' > '
            />
          </div>
        </div>
        <div className={sharedStyles.verticalContainer}>
          <div className={styles.verticalContainerInner}>
            <h1>{post.title}</h1>
            {post.excerpt.length > 0 ? (
              <span className={styles.postExcerpt}>
                {parse(post.excerpt.substring(0, 177) + '...')}
              </span>
            ) : ' '}
            <p className={styles.postDate}>
              {formatDateWithDayJs(post.date)} |{' '}
              {post.postFieldGroup.minuteRead} Minute Read
            </p>
            <div className={styles.detailContainer}>
              <div className={styles.postDetailAuthor}>
                <img
                  alt={post.author.node.name}
                  className={styles.postDetailAuthorAvatar}
                  height={64}
                  src={authorImageUrl}
                  width={64}
                />
                <div className={styles.postDetailText}>
                  <a
                    className={styles.authorName}
                    href={`/member/${post.author.node.firstName.toLowerCase()}-${post.author.node.lastName.toLowerCase()}`}
                  >
                    {post.author.node.name}
                  </a>
                  {
                    teamMembers.map((item, index) => {
                      if (post.author.node.name === `${item.node.membersFieldGroup.memberFirstName} ${item.node.membersFieldGroup.memberSurname}`) {
                        return <p key={index.toString()}>{item.node.membersFieldGroup.memberJobTitle}</p>
                      }
                    })
                  }
                </div>
              </div>
              <ShareSmall url={url} />
            </div>
          </div>
        </div>
        <div className={sharedStyles.verticalContainer}>
          {post.featuredImage && (
            <div className={styles.postImage}>
              <img
                alt='Deimos Fallback Image'
                src={post.featuredImage.node.sourceUrl}
              />
            </div>
          )}
        </div>
        <div className={sharedStyles.verticalContainer}>
          <div className={styles.verticalContainerInner}>
            <div
              className={styles.postBody}
              dangerouslySetInnerHTML={{ __html: post.content }}
            />

            <ShareLarge
              pageName='blog post'
              url={url} />

            {knowledgeGains && (
              <div className={styles.gainsWrapper}>
                <h3 className={styles.gainsTitle}>Gain more knowledge</h3>
                {knowledgeGains.slice(0, 4).map(knowledgeGain => {
                  const gainedMore = knowledgeGain.gainMoreKnowledge
                  return (
                    <Link to={`/post/${gainedMore.slug}`}>
                      <ul className={styles.gainsContainer}>
                        <li className={styles.gainsList}>
                          <span className={styles.gainsLink}>
                            {gainedMore.title}
                          </span>
                        </li>
                      </ul>
                    </Link>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

BlogDetailPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default BlogDetailPage
